import React, {useEffect, useState} from "react";
import {
    CartesianGrid,
    ResponsiveContainer,
    Tooltip as ChartTooltip,
    XAxis,
    Area,
    YAxis,
    ComposedChart
} from "recharts";
import moment from "moment";
import {DatePicker, Row, Spin} from "antd";
import Api from "../../api";
import {LoadingOutlined} from "@ant-design/icons";
const { RangePicker } = DatePicker;

const DailyPayments = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);

        Api.get('/admin/daily-payments').then(res => {
            setData(res.data);
            setLoading(false);
        })
    }, [])

    // Format for XAxis
    const formatXAxis = (tickItem) => {
        return moment(new Date(tickItem)).format('D. MMM');
    };

    // Formatter function for YAxis
    const formatYAxis = (value) => {
        // Check if the value is a number
        if (typeof value === 'number') {
            return `€${value.toFixed(2)}`;
        }

        return `€0.00`; // Default value if not a number
    };

    // Formatter function for Tooltip
    const formatTooltip = (value) => {
        // Check if the value is a number
        return value + ' EUR';
    };

    const handleRangeChange = (dates, dateStrings) => {
        if (dates) {
            const startDateString = dateStrings[0];
            const endDateString = dateStrings[1];

            loadData(startDateString, endDateString);
        } else {
            loadData();
        }
    };

    const loadData = (startDate, endDate) => {
        let query = startDate && endDate ? `?start_date=${startDate}&end_date=${endDate}` : null;

        setLoading(true);

        Api.get('/admin/daily-payments' + query).then(res => {
            setData(res.data);
            setLoading(false);
        })
    }

    const maxPayment = Math.max(...data.map(item => item.total_payment));

    return (
        <>
            <Row type="flex" justify="space-between" align="middle">
                <h1>Revenue</h1>
                <RangePicker onChange={handleRangeChange}/>
            </Row>

            {loading && (
                <Spin indicator={<LoadingOutlined style={{fontSize: 30}} spin/>} className="spinner-loader"/>
            )}

            <ResponsiveContainer className="goal-graph-container" width={"100%"} height={200}>
                <ComposedChart
                    height={200}
                    data={data}
                    syncId="paymentChart"
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv2" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="limegreen" stopOpacity={0.3}/>
                            <stop offset="95%" stopColor="limegreen" stopOpacity={0}/>
                        </linearGradient>
                    </defs>

                    <CartesianGrid strokeDasharray="3 3"/>

                    <XAxis tickFormatter={formatXAxis} type="category" dataKey="date"/>

                    <YAxis tickFormatter={formatYAxis} width={80} type="number" domain={[0, maxPayment + 100]}/>

                    <ChartTooltip labelFormatter={(value) => moment(new Date(value)).format('DD.MM.YYYY')}
                                  formatter={formatTooltip}/>

                    <CartesianGrid stroke="#f5f5f5"/>

                    <Area type="monotone" name={'Maksed'} dataKey="total_payment" stroke="limegreen"
                          fill="url(#colorUv2)"/>
                </ComposedChart>
            </ResponsiveContainer>
        </>
    );
}

export default DailyPayments;