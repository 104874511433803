import React, {useEffect, useState} from "react";
import {Layout, Breadcrumb, Input, Tabs, Table, Tag, Space, Popconfirm, message} from 'antd';
import type { SortableContainerProps, SortEnd } from 'react-sortable-hoc';
import type { ColumnsType } from 'antd/es/table';
import { arrayMoveImmutable } from 'array-move';
import {Link} from "react-router-dom"
import '../css/draggable.scss'
import Api from "../api";

import {DeleteOutlined, EditOutlined, MenuOutlined} from '@ant-design/icons';
import NewTrainingVideo from "../components/new-training-video";
import NewTrainingVideoCategory from "../components/new-training-video-category";
import EditTrainingVideoCategories from "../components/edit-training-video-categories";
import {SortableContainer, SortableElement, SortableHandle} from "react-sortable-hoc";

const {TabPane} = Tabs;
const {Content} = Layout;

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    index: number;
}

const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
));
const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
));

const TrainingVideosView = () => {
    const [videos, setVideos] = useState([]);
    const [categories, setCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [active, setActive] = useState('fitlap');
    const [editVideoData, setEditVideo] = useState('');
    const [dataSource, setDataSource] = useState([]);
    const [editCategory, setEditCategory] = useState(false);

    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

    const categoryColumnsDraggable: ColumnsType<DataType> = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: "3%",
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: "20%",
        },
        {
            title: 'Order',
            dataIndex: 'index',
            key: 'index',
            width: "5%",
        },
        {
            title: 'Icon',
            dataIndex: 'icon',
            key: 'icon',
            render: (text, record) =>
                <img style={{maxWidth: 40}} src={text} alt=""/>
        },
        {
            title: 'is_muscle_group',
            dataIndex: 'is_muscle_group',
            key: 'is_muscle_group',
            width: 20,
        },
        {
            title: '# of videos',
            key: 'videos',
            render: (videos, record) => <span>{record.videos.length}</span>,
            width: 20,
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <span style={{cursor: "pointer", color: "#1668dc"}} onClick={() => setEditCategory(record)}>
                        Edit <EditOutlined/>
                    </span>
                </Space>,
            width: "100px",
        },
    ];

    const editVideo = (record) => {
        setEditVideo(record)
    }

    const deleteCategory = (id) => {
        Api.delete('/admin/training-videos/categories/' + id).then(res => {
            loadCategories();
            message.success('Category deleted');
        })
    }

    const loadVideos = () => {
        Api.get('/admin/training-videos/videos').then(response => {
            setVideos(response.data.data);
        })
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '3%'
        },
        {
            title: 'Image',
            dataIndex: 'image',
            key: 'image',
            render: (image, record) => <img className="recipe-img" src={image}/>,
            width: '8%'

        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '30%'
        },
        {
            title: 'Edit categories',
            render: (categories, record) => <EditTrainingVideoCategories video={record} allCategories={allCategories} categories={record.category} loadVideos={loadVideos} ></EditTrainingVideoCategories>,
            width: 5
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            render: (categories, record) => categories.map((category) =>
                    <Tag
                        className="edit-tag"
                        key={category.id}
                    >
                        <span>
                            {category.name}
                        </span>
                    </Tag>
            ),
        },
        {
            title: "Action",
            key: "action",
            render: (text, record) =>
                <Space size="middle">
                    <a onClick={() => editVideo(record)} key="edit"><EditOutlined/> Edit</a>

                    <Popconfirm
                        title={`Are you sure you want to delete: ${record.title}?`}
                        onConfirm={() => deleteVideo(record.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a key="delete-comment" className="delete-comment"><DeleteOutlined/> delete</a>
                    </Popconfirm>
                </Space>,
            width: '10%',
        },
    ];

    const updateCategoryOrder = (order) => {
        Api.put('/admin/training-videos/categories/order', {"order": order}).then(response => {
            console.log(response.data.data)
            loadCategories()
        })
    }

    const deleteVideo = (id) => {
        Api.delete('/admin/training-videos/videos/' + id).then(res => {
            loadVideos();
            message.success('Video deleted');
        })
    }

    useEffect(() => {
        loadVideos()
        loadCategories()
    }, [active])




    const loadCategories = () => {
        Api.get('/admin/training-videos').then(response => {
            setCategories(response.data.data);
            setDataSource(response.data.data);
            setAllCategories(response.data.data);
        })
    };

    const renderTabContent = (type) => {
        if (type === 'categories-draggable') {
            return (
                <Table
                    pagination={false}
                    dataSource={dataSource}
                    columns={categoryColumnsDraggable}
                    rowKey="index"
                    components={{
                        body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                        },
                    }}
                />
                // <Table columns={categoryColumnsDraggable} dataSource={data} pagination={false}/>
            )
        }
        return (
            <Table columns={columns} dataSource={videos} pagination={false}/>
        )
    }


    const changeTab = (value) => {
        setActive(value);
    }

    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex).filter(
                (el: DataType) => !!el,
            );
            console.log('Sorted items: ', newData);
            setDataSource(newData);
            updateCategoryOrder(newData)
        }
    };
    const DraggableContainer = (props: SortableContainerProps) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={onSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    return (
        <Content style={{margin: '0 16px'}}>
            <Breadcrumb style={{margin: '16px 0'}}>
                <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
            </Breadcrumb>

            <div className="site-layout-card">
                <h1>Videod</h1>

                <Tabs defaultActiveKey="1" onChange={changeTab}>
                    <TabPane tab="Videos" key="videos">
                        <NewTrainingVideo editVideoData={editVideoData} loadVideos={loadVideos} setEditVideo={setEditVideo}/>
                        <div style={{marginBottom: 15}}></div>
                        {renderTabContent('videos')}
                    </TabPane>

                    <TabPane tab="Categories" key="categories-draggable">
                        <NewTrainingVideoCategory editCategory={editCategory} setEditCategory={setEditCategory} setCategories={setCategories} categories={categories}
                                                  loadCategories={loadCategories}/>
                        <div style={{marginBottom: 15}}></div>
                        {renderTabContent('categories-draggable')}
                    </TabPane>

                </Tabs>
            </div>
        </Content>
    );
}

export default TrainingVideosView;
