import React, {useEffect, useState} from "react";
import {Form, Input, Button, Space, Modal, message, InputNumber} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import Api from "../api";

const {TextArea} = Input;

const NewTrainingVideo = ({loadVideos, editVideoData, setEditVideo}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [videoData, setVideoData] = useState(null);

    useEffect(() => {
        if (editVideoData) {
            setIsModalVisible(true);
            setVideoData(editVideoData);  // Set the edit video data
        }
    }, [editVideoData]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const onFinish = (values) => {
        const type = editVideoData ? 'put' : 'post';

        Api[type]("/admin/training-videos/videos" + (editVideoData ? '/' + editVideoData.id : ''), values).then(res => {
            setIsModalVisible(false);
            loadVideos();
            return message.success('Success! New video added!');
        }).catch(e => {
            return message.error(`${e.message}`);
        });
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setEditVideo(null);
    };

    return (
        <>
            <Button className="add-translation-btn" type="primary" onClick={showModal}>
                <PlusOutlined/> Add new video
            </Button>

            <Modal title={videoData ? "Edit Video" : "Add New Video"}
                   open={isModalVisible}
                   onCancel={handleCancel}
                   footer={null}
                   destroyOnClose={true}
            >
                <Form
                    name="add-training-video"
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                    onFinish={onFinish}
                    initialValues={videoData || {}}  // Prefill with edit video data
                    autoComplete="off"
                >
                    <Form.Item
                        label="Video URL"
                        name="url"
                        rules={[{required: true, message: 'Please insert video URL'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Title"
                        name="title"
                        rules={[{required: true, message: 'Please insert video title'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="Runtime"
                        name="time"
                        rules={[{required: true, message: 'Please insert video runtime'}]}>
                        <InputNumber defaultValue={0}/>
                    </Form.Item>
                    <Form.Item
                        label="Video image URL"
                        name="image"
                    >
                        <Input/> <small>YouTube embed links will get the image automatically (leave this blank)</small>
                    </Form.Item>
                    <Form.Item
                        label="Description"
                        name="description"
                    >
                        <TextArea/>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 8, span: 16}}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default NewTrainingVideo;