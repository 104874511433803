import React, {useEffect, useState} from "react";
import {
    Form,
    Input,
    Button,
    Modal,
    message,
    InputNumber,
    Switch, Collapse, Space, Select, Alert, Checkbox
} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import Api from "../api";
import config from '../config/config'
import TextArea from "antd/es/input/TextArea";
import {redirect} from "../helpers";

const NewIngredient = ({loadIngredients}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { Panel } = Collapse;
    const [categories, setCategories] = useState(false);
    const [subGroups, setSubGroups] = useState(false);
    const [customUnits, setCustomUnits] = useState([]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    useEffect(() => {
        getFunctions();
    }, []);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getFunctions = () => {
        if (categories === false) {
            Api.get('/admin/functions').then(response => {
                setCategories(response.data.categories);
                setSubGroups(response.data.substituteGroups);
                handleCustomUnits(response.data.customUnits);
            });
        }
    }

    const handleCustomUnits = (customUnits) => {
        const formatted = customUnits.map(el => ({ value: el.id, label: el.singular }));

        setCustomUnits(formatted);
    }

    const onFinish = (values) => {
        values.price = values.price ? values.price / 10 : 0;

        // Remove any key from values where the value is an empty string
        Object.keys(values).forEach(key => {
            if (values[key] === "") {
                delete values[key];
            }
        });

        Api.post("/admin/ingredients", values)
            .then(res => {
                setIsModalVisible(false);
                message.success('Ingredient saved successfully!');
                console.log('Response:', res.data);

                loadIngredients();
                // redirect('/ingredient/' + res.data.id);
            })
            .catch(error => {
                message.error('Failed to save ingredient. Please try again.');
                console.error('Save error:', error);
            });
    };

    const onFinishFailed = (errorInfo) => {
        message.error('Failed: please fix the shown errors');
    };

    return (
        <>
            <Space>
                <Button className="add-ingredient-btn" type="primary" onClick={showModal}>
                    <PlusOutlined /> Add new ingredient
                </Button>
            </Space>

            <Modal
                title="Uus Toiduaine"
                visible={isModalVisible}
                onCancel={handleCancel}
                footer={null}
                width={1000}
            >
                <Form
                    name="basic"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Alert message="Allergeenid ja Nutridata saab sisestada peale toiduaine loomist!" type="info" />
                    <br />
                    <Form.Item
                        label="Avalik nimi"
                        name="original_name"
                        rules={[{ required: true, message: 'Palun sisesta toote avalik nimi' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Osastavas käändes nimi"
                        name="partitive_name"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Privaatne nimi"
                        name="private_name"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item label="Kategooria" name="ingredient_category_id" rules={[{ required: true, message: 'Palun vali toote kategooria' }]}>
                        <Select options={categories} defaultValue={'-- Vali --'} />
                    </Form.Item>

                    <Form.Item label="Asenduste grupp" name="substitute_group">
                        <Select options={subGroups} defaultValue={'-- Vali --'} />
                    </Form.Item>

                    <Form.Item label="Energia (kcal)" name="energy">
                        <InputNumber min="0" step="0.01" stringMode />
                    </Form.Item>

                    <Form.Item label="Süsivesikud (g)" name="carbohydrate">
                        <InputNumber min="0" step="0.01" stringMode />
                    </Form.Item>

                    <Form.Item label="Valgud (g)" name="protein">
                        <InputNumber min="0" step="0.01" stringMode />
                    </Form.Item>

                    <Form.Item label="Rasvad (g)" name="fat">
                        <InputNumber min="0" step="0.01" stringMode />
                    </Form.Item>

                    <Form.Item label="Hind (kg)" name="price">
                        <InputNumber min="0" step="0.01" stringMode />
                    </Form.Item>

                    {/* Corrected typo here */}
                    <Form.Item label="Hinna klass" name="price_range">
                        <Select defaultValue={'-- Vali --'}>
                            <Select.Option value="0">-- Vali --</Select.Option>
                            <Select.Option value="1">Odav</Select.Option>
                            <Select.Option value="2">Keskmine</Select.Option>
                            <Select.Option value="3">Kallis</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Minimaalne kogus (g)" name="min_amount">
                        <InputNumber min="0" step="0.01" stringMode />
                    </Form.Item>

                    <Form.Item label="Aktiivne" name="active" valuePropName="checked">
                        <Switch />
                    </Form.Item>

                    <hr />

                    <Form.Item label="Valmis koguse kordaja" name="cooked_amount_factor">
                        <InputNumber min="0" step="0.01" stringMode />
                    </Form.Item>

                    <Form.Item label="Ostulink (URL)" name="order_url">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Logo (URL)" name="logo_image">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Logo pilt (URL) (~50 x 30 px)" name="logo_landing_page">
                        <Input />
                    </Form.Item>

                    <Form.Item label="Vabavara" name="no_limit" valuePropName="checked">
                        <Checkbox value="1"></Checkbox>
                    </Form.Item>
                    <Form.Item label="Muna" name="is_egg" valuePropName="checked">
                        <Checkbox value="1"></Checkbox>
                    </Form.Item>

                    <hr />

                    <Form.Item label="Märkmed" name="notes">
                        <TextArea />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            Salvesta
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default NewIngredient;