import React, {useEffect, useState} from "react";
import {Avatar, DatePicker, List, Row, Spin, Tag} from 'antd';
import moment from "moment";
import Api from "../../api";
import {Link} from "react-router-dom";
import InvoicesTable from "../invoices-table";

const LatestInvoices = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Function to fetch data
        const fetchData = () => {
            Api.get('/admin/latest-invoices?status=1').then(res => {
                setData(res.data.data);
                setLoading(false);
            }).catch(error => {
                console.error("Error fetching data:", error);
            });
        };

        // Fetch data immediately on mount
        fetchData();

        // Set up the interval to fetch data every 60 seconds
        const intervalId = setInterval(fetchData, 60000);

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [])

    return (
        <>
            <Row type="flex" justify="space-between" align="middle">
                <h1>Latest payments</h1>
            </Row>

            <InvoicesTable hideUtm={true} invoices={data}/>
        </>
    );
}

export default LatestInvoices;